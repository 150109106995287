import React, { useContext, useEffect, useState } from 'react';

import { LangContext } from '../App';

import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

const Facts = () => {
    useFirestoreConnect ([{ collection: 'positions' }]);
    const lang = useContext(LangContext);
    const positions = useSelector(state => state.firestore.ordered.positions);
    const [openPositions, setOpenPositions] = useState([])

    useEffect(() => {
        isLoaded(positions) && setOpenPositions([...positions.filter(pos => pos.value !== 0)])
    }, [positions])
    
    return (
        <section id="facts">
            <div className="container wow fadeIn">
                <div className="section-header">
                    <h3 className="section-title">{lang === 'ENG' ? 'Open Positions':'Otvorene pozicije'}</h3>
                    <p className="section-description">{lang === 'ENG' ? 'We are currently looking for:':'Trenurno smo u potrazi za:'}</p>
                </div>
                <div className="row counters">
                    {
                        openPositions.length && openPositions.map((pos,index) => {
                            return (
                                <div className="col-lg-3 col-6 text-center" key={index}>
                                    <span data-toggle="counter-up">{parseInt(pos.value)}</span>
                                    <p>{lang === 'ENG' ? pos.name:pos.nameSrb}</p>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        </section>
    )
}

export default Facts;
