import React, { useContext } from 'react';
import { LangContext } from '../App';

const Hero = () => {
    const lang = useContext(LangContext);
    return (
        <section id="hero">
            <div className="hero-container">
            <h1>River Cruise Life</h1>
            <h2>{ lang === 'ENG' ? 'employment agency':'agencija za zapošljavanje'}</h2>
            <a href="#about" className="btn-get-started">{ lang === 'ENG' ? 'Get Started':'Započni' }</a>
            </div>
        </section>
    )
}

export default Hero;
