import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBsILQ1Eu_bpwPsp1XYYDX7wiv1-foGgb0",
    authDomain: "river-cruise-life.firebaseapp.com",
    projectId: "river-cruise-life",
    storageBucket: "river-cruise-life.appspot.com",
    messagingSenderId: "715313438713",
    appId: "1:715313438713:web:4e58b3c3f456c2d3526728"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestapmsInSnapshots: true });

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();

export { projectStorage, projectFirestore, firebaseConfig };

export default firebase;