import React, { useState, useEffect, useContext } from 'react';
import { LangContext } from '../App';

import { useSelector } from 'react-redux';

//icons
import { BsFillStarFill, BsX } from 'react-icons/bs';
import { IconButton } from '@material-ui/core';

import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';


const Cruisers = () => {
  useFirestoreConnect ([{ collection: 'cruisers' }]);
  const lang = useContext(LangContext);
  const cruisers = useSelector(state => state.firestore.ordered.cruisers);
  const [cruiserList, setCruiserList] = useState([]);
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    isLoaded(cruisers) && setCruiserList([...cruisers])
  },[cruisers])
  
  return (
    <section id="portfolio">
      <div className="container wow fadeInUp">
        <div className="section-header">
          <h3 className="section-title">{lang === 'ENG' ? 'Cruisers':'Kruzeri'}</h3>
          <p className="section-description">{lang === 'ENG' ? 'You can check cruiser details by clicking on a image':'Možete pogledati detaljan opis kruzera klikom na sliku'}</p>
        </div>
        <div className="row">
        {
          cruiserList.length && cruiserList.map((crus, index) => {
            return (
              <div className="col-lg-4 col-md-6" key={Math.random()}>
                <div className="member" onClick={() => setIsOpen(index)}>
                  <div className="pic"  style={{ backgroundImage: `url(${crus.imgUrl})` }}>
                    <div className="cruiser-overlay"><h5>{crus.name}</h5></div>
                  </div>
                </div>
                { isOpen === index ?
                <div id="open-modal" className="modal-window">
                  <div>
                    <div  title="Close" className="modal-close"><IconButton onClick={() => setIsOpen(null)}><BsX /></IconButton></div>
                    <h1>{crus.name}</h1>
                    <div className="modal-stars">
                      {
                        [...Array(parseInt(crus.stars))].map(star => {
                          return <BsFillStarFill key={Math.random()}/>
                        })
                      }
                    </div>
                    <img src={crus.imgUrl} className="modal-image" alt={crus.name}/>
                    <div className="cruiser-description">{lang === 'ENG' ? crus.description:crus.opis}</div>
                    <div className="row">
                      <div className="col-sm-4 ">{crus.crew}<span>{lang === 'ENG' ? 'Crew':'Posada'}</span></div>
                      <div className="col-sm-4 ">{crus.passengers}<span>{lang === 'ENG' ? 'Paseengers':'Putnika'}</span></div>
                      <div className="col-sm-4 ">({crus.flag})<span>{lang === 'ENG' ? 'Flag':'Zastava'}</span></div>
                    </div>
                    </div>
                </div>:<div className="modal-close"></div>
                }
              </div>
            )
          })
        }
        </div>
      </div>
    </section>
  )
}

export default Cruisers;
