const initState = {
    cruisers: [],
    error: null,
    progress: null,
    status: null
}

const cruisersReducer = (state = initState, action) => {
    switch(action.type){
        case 'CRUISER_DELETED': 
            return {
                ...state,
                cruisers: [...state.cruisers.filter(cruis => cruis.name !== action.payload)],
                status: `Cruiser ${action.payload} deleted.`,
                error: null
            }
        case 'UPDATED_CRUISER':
            return {
                ...state,
                cruisers: [...state.cruisers, action.payload],
                status: `Cruiser ${action.payload.name} updated.`,
                error: null
            }
        case 'UPDATE_CRUISER_ERROR':
        case 'CRUISER_DELETED_ERROR':
        case 'FILE_UPLOAD_ERROR':
        case 'CREATE_CRUISER_ERROR':
            return {
                ...state,
                error: action.payload.message
            }
        case 'FETCH_CRUISERS':
            return {
                ...state,
                cruisers: [...action.payload]
            }
        case 'PROGRESS':
            return {
                ...state,
                progress: action.payload
            }
        case 'CREATE_CRUISER':
            return {
                ...state,
                cruisers: [...state.cruisers, action.payload],
                status: `Cruiser ${action.payload.name} created.`,
                error: null
            }
        default:
            return state;
    }
}

export default cruisersReducer;