import React, { useEffect, useState } from 'react';

import { ListItem, Avatar, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Grid, List, Typography } from '@material-ui/core';

import { Person, Delete, Edit } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { useFirestoreConnect , isLoaded, isEmpty } from 'react-redux-firebase';

import useStyles from './styles';

const OpenPositionsList = ({ currentId, handleDelete, handleEdit}) => {
    const classes = useStyles();
    useFirestoreConnect ([{ collection: 'positions' }]);

    const positions = useSelector(state => state.firestore.ordered.positions);
    const [positionsList, setPositionsList] = useState([]);
    
    useEffect(() => {
        isLoaded(positions) && setPositionsList([...positions])
    }, [positions])

    
    if(!isLoaded(positions)){
        return <div>Loading...</div>
    }

    if(isEmpty(positions)){
        return <div>List is empty</div>
    }

    return (
        <Grid item xs={12} md={12} lg={8} xl={6}>                 
            <List dense  className={classes.demo}>
                {
                    positionsList.length ? positionsList.map(pos => {
                        return (   
                        <ListItem key={Math.random()} divider className={currentId === pos.id ? classes.markedRow:''}>
                        <ListItemAvatar>
                            <Avatar>
                            <Person />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${pos.name} / ${pos.nameSrb}`}
                            secondary={`open: ${pos.value}`}
                            className={classes.info}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" color="primary" onClick={() => handleEdit(pos)}>
                            <Edit />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" className={classes.deleteBtn} onClick={() => handleDelete(pos)}>
                            <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                        </ListItem>
                    )}): <ListItem><Typography variant="h5" className={classes.epmtyList}>List is empty...</Typography></ListItem>
                }
            </List>
        </Grid>
    )
}

export default OpenPositionsList;
