import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { useSelector } from 'react-redux';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InfoSnackbar = () => {
    const [open, setOpen] = useState(false);
    const error = useSelector(state => state.cruisers.error);
    const status = useSelector(state => state.cruisers.status);
    
    useEffect(() => {
        if(error !== null || status !== null)
        setOpen(true);
        return setOpen(false);
    }, [error, status])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <>
        {
            error !== null ?
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">{error}</Alert>               
            </Snackbar>: status !== null ?
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">{status}</Alert>           
            </Snackbar>:''

        }
        </>
    )
}

export default InfoSnackbar;
