import axios from 'axios';

export const sendApplication = (application, file) => async (dispatch) => {

    // form data
    let formData = new FormData();
    Object.keys(application).forEach(key => key !== 'police' ? formData.append(key, application[key]):null)
    formData.append('pdf', file, file.name);

    try{
        dispatch({ type: 'APPLY_PROGRESS', payload: true }); 
        const result = await 
        axios({
            method: "post",
            url: "https://sphenoid-spicy-honeycup.glitch.me/send",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
          })

            /*
            dispatch({ type: 'APPLY_PROGRESS', payload: false });
            dispatch({ type: 'APPLY_SUCCESS', payload: 'SUCCESS' });
    
            dispatch({ type: 'APPLY_ERROR', payload: 'ERROR' });
            alert('Something went wrong, try again later.')
            */
        if(result.status === 200){
            dispatch({ type: 'APPLY_PROGRESS', payload: false });
            dispatch({ type: 'APPLY_SUCCESS', payload: result.data.msg });
            dispatch({ type: 'RES_STATUS', payload: result.status })
        } else {
            dispatch({ type: 'APPLY_ERROR', payload: result.data.msg });
            dispatch({ type: 'APPLY_PROGRESS', payload: false });
            dispatch({ type: 'RES_STATUS', payload: result.status })
        }
    }
    catch {
        dispatch({ type: 'APPLY_ERROR', payload: 'Something went wrong, try again later.' });
        dispatch({ type: 'APPLY_PROGRESS', payload: false });
    }
}