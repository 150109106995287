import React, { useState, useEffect } from 'react';

import { Button, Paper, Grid, Typography, Avatar, TextField } from '@material-ui/core';
import Input from '../Input';
import ProgressBar from '../ProgressBar';
import CruiserList from './CruiserList';
import InfoSnackbar from './InfoSnackbar';

import useStyles from '../styles';

import { createCruiser, updateCruiser, deleteCruiser } from '../../../actions/cruisers';
import { useDispatch, useSelector } from "react-redux";

const Cruisers = () => {
    const dispatch = useDispatch();
    const progress = useSelector(state => state.cruisers.progress);
    const classes = useStyles();
    const [imgFormatError, setImgFormatError] = useState(null);
    const [file, setFile] = useState(null);
    const [cruiser, setCruiser] = useState({
        name:'',
        description:'',
        opis:'',
        passengers: 0,
        crew: 0,
        flag: '',
        stars: 0
    })

    useEffect(() => {        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }, [cruiser.id])

    const handleEdit = (cruis) => {
        setCruiser(cruis);
    }

    const handleDelete = (cruiser) => {
        dispatch(deleteCruiser(cruiser));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(cruiser.id){
            dispatch(updateCruiser(file, cruiser));
            handleClear();
        } else {
            if(!file){
                setImgFormatError('please select file')
            } else {
                dispatch(createCruiser(file, cruiser));
                handleClear();
            }
        }
    }

    const handleChange = (e) => {
        let name = e.target.name;
        setCruiser(curr => {
            return {
                ...curr,
                [name]: e.target.value
            }
        })
    }

    const types = ['image/png', 'image/jpeg']

    const imgPickerHandler = (e) => {
        let selected = e.target.files[0];    

        if (selected && types.includes(selected.type)) {
        setFile(selected);
        setImgFormatError(null);
        } else {
        setFile(null);
        setImgFormatError('Please select png/jpeg file!')
        }
    }

    const handleClear = (e) => {
        document.getElementById("file").value = "";
        setCruiser({
            name:'',
            description:'',
            opis:'',
            passengers: 0,
            crew: 0,
            flag: '',
            stars: 0
        });
        setFile(null);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={4}>
                <Paper className={classes.paper}>
                    <Avatar className={cruiser.id ? classes.avatarFormAdd:classes.avatarFormEdit}>
                        <i className="fa fa-ship"></i>
                    </Avatar>
                    <Typography variant="h5">{cruiser.id ? 'Edit Cruiser':'New Cruiser'}</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Input value={cruiser.name} name="name" label="Name" handleChange={handleChange} type="text" third2/>
                            <Input value={cruiser.flag} name="flag" label="Flag"  handleChange={handleChange} type="text" third/>
                            <Input value={cruiser.description} name="description" label="Description" multiline handleChange={handleChange} type="text"/>
                            <Input value={cruiser.opis} name="opis" label="Opis" multiline handleChange={handleChange} type="text"/>
                            <Input value={cruiser.passengers} name="passengers" label="Passengers"  handleChange={handleChange} type="number" third/>
                            <Input value={cruiser.crew} name="crew" label="Crew"  handleChange={handleChange} type="number" third/>
                            <Input value={cruiser.stars} name="stars" label="Stars" handleChange={handleChange} type="number" third/>
                            <Grid item xs={12} align="center">
                                <TextField id="file" variant="outlined" type="file" onChange={imgPickerHandler} />
                                <br/>
                                {imgFormatError ? <Typography variant="caption" color="error">{imgFormatError}</Typography>:''}
                            </Grid>
                            <Grid item xs={12}>
                                <Button size="large" type="submit" fullWidth variant="outlined" className={cruiser.id ? classes.btnEdit:classes.btnAdd}>{cruiser.id ? 'Edit':'Add'}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button size="small" fullWidth variant="outlined" className={classes.btnClear} onClick={handleClear}>Clear</Button>
                            </Grid>
                        </Grid>
                    </form>
                    { progress && <ProgressBar value={progress} /> }
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <CruiserList handleEdit={handleEdit} currentId={cruiser.id} handleDelete={handleDelete}/>
            </Grid>
            <InfoSnackbar />
        </Grid>
    )
}

export default Cruisers;
