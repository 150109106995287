import { projectFirestore } from '../config/fbConfig';

export const addPosition = (position) => {
    return (dispatch) => {

        const collectionRef = projectFirestore.collection('positions');

        collectionRef.add(position).then(() => {
            dispatch({ type: 'ADD_POSITION', payload:position })
        }).catch(err => {
            dispatch({ type: 'ADD_POSITION_ERROR', payload: err})
        })

    }
}

export const editPosition = (position) => {
    return (dispatch) => {
        let positionToUpdate = {...position};
        const docRef = projectFirestore.collection('positions').doc(position.id);
        delete positionToUpdate['id'];   

        docRef.update({ ...positionToUpdate })
            .then(() => {
                dispatch({ type: 'POSITION_UPDATED', payload:{...positionToUpdate } });
            }).catch((err) => {
                dispatch({ type: 'POSITION_UPDATE_ERROR', payload: err.message });
            })
    }
}

export const deletePosition = (position) => {
    return (dispatch) => {
        const docRef = projectFirestore.collection('positions').doc(position.id);

        docRef.delete().then(() => {
            dispatch({ type: 'POSITION_DELETED', payload: position.name});
        }).catch(err => {
            dispatch({ type: 'POSITION_DELETE_ERROR', payload: err})
        })
    }
}