import React, { useContext } from 'react';

import { LangContext } from '../App';

// text content
import { about_text } from './textContent';

const About = () => {
    const lang = useContext(LangContext);

    return (
        <section id="about">
            <div className="container">
                <div className="row about-container">

                    <div className="col-lg-6 content order-lg-1 order-2">
                        <h2 className="title">{about_text[lang].heading.title}</h2>
                        <p>{about_text[lang].heading.text}</p>

                        <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
                            <div className="icon"><i className="fa fa-ship"></i></div>
                            <h4 className="title"><a href="#about">{about_text[lang].div1.title}</a></h4>
                            <p className="description">{about_text[lang].div1.text}</p>
                        </div>

                        <div className="icon-box wow fadeInUp">
                            <div className="icon"><i className="fa fa-crosshairs"></i></div>
                            <h4 className="title"><a href="#facts">{about_text[lang].div3.title}</a></h4>
                            <p className="description">{about_text[lang].div3.text}</p>
                        </div>

                    </div>

                    <div className="col-lg-6 background order-lg-2 order-1 wow fadeInRight"></div>
                </div>

            </div>
        </section>
    )
}

export default About;
