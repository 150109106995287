import { projectStorage, projectFirestore } from '../config/fbConfig';

export const createJob = (file, job) => {
    return (dispatch) => {
        const storageRef = projectStorage.ref('jobs/' + file.name);
        const collectionRef = projectFirestore.collection('jobs');

        storageRef.put(file).on('state_changed', (snap) => {
            let precentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            dispatch({ type: 'PROGRESS', payload:precentage });
        }, (err) => {
            dispatch({ type: 'FILE_UPLOAD_ERROR', payload:err });
        }, async () => {
            const url = await storageRef.getDownloadURL();
            collectionRef.add({ ...job, fileUrl: url })
                .then(() => {
                    dispatch({ type: 'JOB_CREATED', payload: { ...job, fileUrl: url }});
                    dispatch({  type: 'PROGRESS', payload:null });
                }).catch(err => {
                    dispatch({ type: 'CREATE_JOB_ERROR', payload: err });
                })
        })
    }
}

export const deleteJob = (job) => {
    return (dispatch) => {
        const docRef = projectFirestore.collection('jobs').doc(job.id);
        docRef.delete().then(() => {
            dispatch({ type: 'JOB_DELETED', payload: job.title })
        }).catch(error => {
            dispatch({ type: 'DELETE_JOB_ERROR', payload: error })
        })
    }
}

export const updateJob = (file, job) => {
    return (dispatch) => {     
        let jobToUpdate = {...job};
        const docRef = projectFirestore.collection('jobs').doc(job.id);
        delete jobToUpdate['id'];   
        
        if(file){
            const storageRef = projectStorage.ref('jobs/' + file.name);
            storageRef.put(file).on('state_changed', (snap) => {
                let precentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                dispatch({ type: 'PROGRESS', payload:precentage })
            }, (err) => {
                dispatch({ type: 'FILE_UPLOAD_ERROR', payload:err.message })
            }, async () => {
                const url = await storageRef.getDownloadURL();
                docRef.update({ ...jobToUpdate, imgUrl: url })
                    .then(() => {
                        dispatch({ type: 'JOB_UPDATED', payload:{...jobToUpdate, imgUrl: url } });
                        dispatch({  type: 'PROGRESS', payload:null });
                    }).catch((err) => {
                        dispatch({ type: 'UPDATE_JOB_ERROR', payload: err.message });
                    })
            })
        } else {
            docRef.update({ ...jobToUpdate })
                    .then(() => {
                        dispatch({ type: 'JOB_UPDATED', payload:{...jobToUpdate } });
                        dispatch({  type: 'PROGRESS', payload:null });
                    }).catch((err) => {
                        dispatch({ type: 'UPDATE_JOB_ERROR', payload: err.message });
                    })
        }
    }
}