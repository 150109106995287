const initState = {
    jobs: [],
    error: null,
    progress: null,
    status: null
}

const jobsReducer = (state = initState, action) => {
    switch(action.type){
        case 'JOB_DELETED':            
            return {
                ...state,
                status: `Job ${action.payload} deleted.`,
                error: null
            }
        case 'JOB_CREATED':                       
            return {
                ...state,
                status: `Job ${action.payload.title} created.`,
                error: null
            }
        case 'JOB_UPDATED':                        
            return {
                ...state,
                status: `Job ${action.payload.title} updated.`,
                error: null
            }
        case 'PROGRESS':
            return {
                ...state,
                progress: action.payload
            };
        case 'FILE_UPLOAD_ERROR':
        case 'CREATE_JOB_ERROR':
        case 'DELETE_JOB_ERROR':
        case 'UPDATE_JOB_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default jobsReducer;