import { projectStorage, projectFirestore } from '../config/fbConfig';

export const createCruiser = (file, cruiser) => {
    return (dispatch, getState) => {
        const storageRef = projectStorage.ref('cruisers/' + file.name);
        const collectionRef = projectFirestore.collection('cruisers');

        storageRef.put(file).on('state_changed', (snap) => {
            let precentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            dispatch({ type: 'PROGRESS', payload:precentage })
        }, (err) => {
            dispatch({ type: 'FILE_UPLOAD_ERROR', payload:err })
        }, async () => {
            const url = await storageRef.getDownloadURL();
            collectionRef.add({ ...cruiser, imgUrl: url })
                .then(() => {
                    dispatch({ type: 'CREATE_CRUISER', payload:{...cruiser, imgUrl: url } });
                    dispatch({  type: 'PROGRESS', payload:null });
                }).catch((err) => {
                    dispatch({ type: 'CREATE_CRUISER_ERROR', payload: err });
                })
        })
    }
}

export const fetchCruisers = () => {
    return (dispatch) => {
        const response = projectFirestore.collection('cruisers');
        response.get().then(data => {
            let cruisers = [];
            data.docs.forEach(item=>{
             cruisers.push({ ...item.data(), id:item.id });
            }) 
            dispatch({ type: 'FETCH_CRUISERS', payload:cruisers})

        })
    }   
}

export const updateCruiser = (file, cruiser) => {
    return (dispatch) => {     
        let cruiserToUpdate = {...cruiser};
        const docRef = projectFirestore.collection('cruisers').doc(cruiser.id);
        delete cruiserToUpdate['id'];   
        
        if(file){
            const storageRef = projectStorage.ref('cruisers/' + file.name);
            storageRef.put(file).on('state_changed', (snap) => {
                let precentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                dispatch({ type: 'PROGRESS', payload:precentage })
            }, (err) => {
                dispatch({ type: 'FILE_UPLOAD_ERROR', payload:err })
            }, async () => {
                const url = await storageRef.getDownloadURL();
                docRef.update({ ...cruiserToUpdate, imgUrl: url })
                    .then(() => {
                        dispatch({ type: 'UPDATED_CRUISER', payload:{...cruiserToUpdate, imgUrl: url } });
                        dispatch({  type: 'PROGRESS', payload:null });
                    }).catch((err) => {
                        dispatch({ type: 'UPDATE_CRUISER_ERROR', payload: err });
                    })
            })
        } else {
            docRef.update({ ...cruiserToUpdate })
                    .then(() => {
                        dispatch({ type: 'UPDATED_CRUISER', payload:{...cruiserToUpdate } });
                        dispatch({  type: 'PROGRESS', payload:null });
                    }).catch((err) => {
                        dispatch({ type: 'UPDATE_CRUISER_ERROR', payload: err });
                    })
        }
    }
}

export const deleteCruiser = (cruiser) => {
    return (dispatch) => {
        const docRef = projectFirestore.collection('cruisers').doc(cruiser.id);
        docRef.delete().then(() => {
            dispatch({ type: 'CRUISER_DELETED', payload: cruiser.name })
        }).catch(error => {
            dispatch({ type: 'CRUISER_DELETED_ERROR', payload: error })
        })
    }
}