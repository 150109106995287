import React, { useState, useContext, useEffect } from 'react';

//icons
import { FaUserTag } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { FaFileDownload } from 'react-icons/fa'

//lang context
import { LangContext } from '../App';

import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const departmentsSetup = (jobs, lang) => {
    let temp = jobs.map(job => lang === 'ENG' ? job.department:job.departmentSrb);
    
    let deps = [];
    for(let i = 0; i < temp.length; i++){
        if(temp[i].includes('/')){
            let splited = temp[i].split('/');
            for(let j = 0; j < splited.length; j++){
                deps.push(splited[j].trim());
            }
            continue;
        } else {
            deps.push(temp[i].trim());
        }
    }
    return [...new Set(deps)];
}


const Services = () => {
    useFirestoreConnect ([{ collection: 'jobs' }]);
    const lang = useContext(LangContext);
    const jobs = useSelector(state => state.firestore.ordered.jobs);
    const [jobsList, setJobsList] = useState([]);
    const [filter, setFilter] = useState('All');
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        isLoaded(jobs) && setDepartments(departmentsSetup(jobs, lang));
        isLoaded(jobs) && setJobsList([...jobs]);
        setFilter('All');
    },[lang, jobs])

    const handleFilter = (dep) => {
        setFilter(dep);
        if(dep !== 'All'){
            let filtered = jobs.filter(job => lang === 'ENG' ? job.department.includes(dep) : job.departmentSrb.includes(dep));
            setJobsList([...filtered]);
        } else {
            setJobsList([...jobs]);
        }
    }

    return (
        <section id="services">
            <div className="container wow fadeIn">
                <div className="section-header">
                    <h3 className="section-title">{lang === 'ENG' ? 'Jobs Descriptions':'Opisi Poslova'}</h3>
                    <p className="section-description">{lang === 'ENG' ? 'You can downlaod complete job description by clicking ':'Možete preuzeti specifikaciju posla klikom na '}<FaFileDownload style={{color: '#e8c30b'}}/></p>
                </div>
                
                <div className="row">

                    <div className="col-lg-12">
                        <ul id="services-flters">
                            <li onClick={() => handleFilter('All')}  className={filter === "All" ? "filter-active":''}>{lang === 'ENG' ? 'All':'Svi'}</li>
                            {
                                departments.map(departmant => {
                                    return (
                                    <li key={departmant} onClick={() => handleFilter(departmant)} className={filter === departmant ? "filter-active":''}>{departmant}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    {
                        jobsList.length && jobsList.map(job => {
                            return (
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s" key={Math.random()}>
                                    <div className="box">
                                        <IconButton className="icon"><FaUserTag /></IconButton>
                                        <h4 className="title">{lang === 'ENG' ? job.title:job.titleSrb}</h4>
                                        <p className="description">
                                            <span>{lang === 'ENG' ? 'raiting':'rang'}<br/><strong>{lang === 'ENG' ? job.raiting:job.raitingSrb}</strong></span>
                                        </p>
                                        <p className="description">
                                            <span>{lang === 'ENG' ? 'department':'odeljenje'}<br/><strong>{lang === 'ENG' ? job.department:job.departmentSrb}</strong> </span>
                                        </p>
                                        <p className="description">
                                            <span>{lang === 'ENG' ? 'cabin':'kabina'}<br/><strong>{lang === 'ENG' ? job.cabin:job.cabinSrb}</strong> </span>
                                        </p>
                                        <IconButton href={job.fileUrl} style={{color: '#e8c30b', fontSize: '2rem'}} target="_blank"><FaFileDownload /></IconButton>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Services
