import React, { useContext } from 'react';

import { LangContext } from '../App';

//routes maps
import AmsterdamBaselBudapest from '../img/routes/AmsterdamBaselBudapest.jpg';
import AmsterdamBasel from '../img/routes/AmsterdamBasel.jpg';
import AmsterdamRotterdamAntwerp from '../img/routes/AmsterdamRotterdamAntwerp.jpg';
import AmsterdamBudapestGrandEuropeCruise from '../img/routes/AmsterdamBudapestGrandEuropeCruise.jpg';
import AmsterdamBlackSea from '../img/routes/AmsterdamBlackSea.jpg';
import BambergBlackSea from '../img/routes/BambergBlackSea.jpg';
import Elba from '../img/routes/Elba.jpg';
import HollandBelgiumCruise from '../img/routes/HollandBelgiumCruise.jpg';
import PassauBudapest from '../img/routes/PassauBudapest.jpg';

const routes = [
  { name: { ENG: 'Amsterdam - Basel - Budapest', SRB: 'Amsterdam - Bazel - Budimpešta' }, img: AmsterdamBaselBudapest },
  { name: { ENG: 'Amsterdam - Basel', SRB: 'Amsterdam - Bazel' }, img: AmsterdamBasel },
  { name: { ENG: 'Amsterdam - Rotterdam - Antwerp', SRB: 'Amsterdam - Roterdam - Antverpen' }, img: AmsterdamRotterdamAntwerp },
  { name: { ENG: 'Amsterdam - Budapest - Grand Europe Cruise', SRB: 'Amsterdam - Budimpešta - Kruz Evrope' }, img: AmsterdamBudapestGrandEuropeCruise },
  { name: { ENG: 'Amsterdam - Black Sea', SRB: 'Amsterdam - Crno More' }, img: AmsterdamBlackSea },
  { name: { ENG: 'Bamberg - Black Sea', SRB: 'Bamberg - Crno more' }, img: BambergBlackSea },
  { name: { ENG: 'Elba', SRB: 'Elba' }, img: Elba },
  { name: { ENG: 'Holland - Belgium Cruise', SRB: 'Holandija - Belgija Kruz' }, img: HollandBelgiumCruise },
  { name: { ENG: 'Passau - Budapest', SRB: 'Pasao - Budimpešta' }, img: PassauBudapest },
]

const Routes = () => {
  const lang = useContext(LangContext);

  return (
    <section id="team">
      <div className="container wow fadeInUp">
        <div className="section-header">
          <h3 className="section-title">{lang === "ENG" ? 'Routes' : 'Rute'}</h3>
          <p className="subtitle">{lang === "ENG" ? 'Explore some of the routes along which the river cruiser sails.' : 'Neke od ruta rečnih kruzeva možete pogledati ovde.'}</p>
        </div>
        <div className="row">
          {
            routes.map(route => {
              return (

                <div className="col-lg-4 col-md-6" key={Math.random()}>
                  <div className="member">
                    <div className="pic"><img src={route.img} alt={route.img} /></div>
                    <h4>{route.name[lang]}</h4>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Routes;
