import React from 'react';

import { TextField, Grid } from '@material-ui/core';

const Input = ({ half, third, third2, name, handleChange, label, type, multiline, value }) => {
    return (
        <Grid item xs={12} sm={half ? 6: third ? 4:third2 ? 8:12}>
            <TextField
                value={value}
                name={name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                label={label}
                type={type}
                multiline={multiline}
                rows={multiline ? 4:1}
                autoComplete='off'
            />
        </Grid>
    )
}

export default Input;
