import React from 'react';

//components
import Header from './Header';
import Hero from './Hero';
import About from './About';
import Services from './Services';
import Facts from './Facts';
import CallToAction from './CallToAction';
import Routes from './Routes';
import Contact from './Contact';
import Footer from './Footer';
import Cruisers from './Cruisers';


const Layout = () => {
   
    return (
        <div id="layout">
            <Header />
            <Hero />
            <main id="main">
                <About />
                <Facts />
                <Services />
                <CallToAction />
                <Cruisers />
                <Routes />
                <Contact />
            </main>
            <Footer />
            <a href="#hero" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
        </div>
    )
}

export default Layout;
