import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { getFirestore, reduxFirestore, createFirestoreInstance } from 'redux-firestore';
import thunk from 'redux-thunk';

import firebase from "./config/fbConfig";
import { firebaseConfig } from "./config/fbConfig";
import rootReducer from './reducers/rootReducer';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Create store with reducers and initial state
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
    reduxFirestore(firebase, firebaseConfig)
  )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}



ReactDOM.render(
<Provider store={store}>
  <ReactReduxFirebaseProvider {...rrfProps}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactReduxFirebaseProvider>
</Provider>,
  document.getElementById('root')
);
