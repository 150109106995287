import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export default makeStyles((theme) => ({
  root: {
    background: 'rgba(52, 59, 64, 0.9)',
    color: '#fff',
    display: 'flex',
    '& .MuiAppBar-colorPrimary': {
      color: 'rgba(52, 59, 64, 0.9)',
      backgroundColor: '#e8c30b'
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#e8c30b',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh'
  },
  main: {
    position: 'relative',
    minHeight: '100vh'
  },
  helper: {
    background: 'rgba(0, 0, 0, 0.2)',
    color: '#ffea92',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    padding: '1.2em 2em',
    textAlign: 'center',
    borderRadius: '20px',
    fontSize: '2em',
    fontWeight: 'bold',
  },
  avatar: {
    background: 'rgba(0, 0, 0, 0.1)',
    padding: '2em 0.5em',
    textAlign: 'center',
    color: 'rgba(52, 59, 64, 0.9)',
    '& .MuiTypography-h5': {
      fontWeight: 700
    }
  },
  avatarLogin: {
    background: '#FFF',
    padding: '2em 0.5em',
    textAlign: 'center',
  },
  menu: {
    background: '#e8c30b',
    height: '100vh',
    width: '240px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
  },
  hideMenu: {
    background: '#e8c30b',
    height: '100vh',
    width: '40px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    
  },
  img: {
    width: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: '4px solid #ffea92',
    boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',
    background: 'rgba(52, 59, 64, 0.9)'
  },
  tabs: {
    color: '#000',
    '& .PrivateTabIndicator-color':{
      backgroundColor: 'rgba(52, 59, 64, 0.9)'
    }
  },
  tab: {
    fontWeight: 600,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  paperLogin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  avatarFormAdd: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(1),
    backgroundColor: 'rgba(63, 81, 181, 0.9)',
    border: '4px solid #3f51b5',
    boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.2)',    
  },
  avatarFormEdit: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(1),
    backgroundColor: 'rgba(52, 59, 64, 0.9)',
    border: '4px solid #ffea92',
    boxShadow: '0 0 0 4px rgba(117, 125, 232, 0.2)',    
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
  btnAdd: {
    fontWeight: 700,
    backgroundColor: 'rgba(52, 59, 64, 0.9)',
    color: '#e8c30b',
    '&:hover': {
      background: '#e8c30b',
      color: 'rgba(52, 59, 64, 0.9)',
   },
  },
  btnEdit: {
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    '&:hover': {
      background: '#FFF',
      color: theme.palette.primary.main,
   },
  },
  btnClear: {
    fontWeight: 700,
    color: 'rgba(52, 59, 64, 0.9)',
    backgroundColor: '#e8c30b',
    '&:hover': {
      color: '#e8c30b',
      background: 'rgba(52, 59, 64, 0.9)',
   },
  },
  auth: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.1)',
    width: '100vw',
    height: '100vh',
  },
  authPaper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px'
  },
  user: {
    position: 'absolute',
    bottom: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    color: 'rgba(52, 59, 64, 0.9)',
    padding: theme.spacing(2),
  },
  userNameBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));