import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab, Box, Typography, Button, Toolbar, IconButton, Hidden, Drawer, CssBaseline, AppBar, useTheme } from '@material-ui/core';
import { Person, Menu } from '@material-ui/icons';

import OpenPositions from './OpenPositions/OpenPositions';
import Cruisers from './Cruisers/Cruisers';
import Jobs from './Jobs/Jobs';
import useStyles from './styles';

//Logo
import Logo from '../../img/logo.png';

//auth
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signOut } from '../../actions/auth';

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const Admin = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const [value, setValue] = useState(0);

  if(!isLoaded(auth)){
    return <div>Loading...</div>
  }
  if(isLoaded(auth) && isEmpty(auth)){
    return <Redirect to='/auth'/>
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(mobileOpen)setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
        <header className={classes.avatar}>
          <Typography variant="h5" gutterBottom >ADMIN</Typography>
          <img src={Logo} alt="logo" className={classes.img} />
        </header>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{ style: { background: "rgba(52, 59, 64, 0.9)" } }}
        >
          <Tab label="Open Positions" {...a11yProps(0)} className={classes.tab} />
          <Tab label="Cruisers" {...a11yProps(1)} className={classes.tab} />
          <Tab label="Jobs" {...a11yProps(2)} className={classes.tab} />
        </Tabs>
        <footer className={classes.user}>
          <div className={classes.userNameBox}>
          <Person fontSize="large"/>
          <Typography variant="h5">{auth.email.split('@')[0]}</Typography>
          </div>
          <Button size="large" type="button" fullWidth variant="outlined" className={classes.btnAdd} onClick={handleSignOut}>Sign Out</Button>
        </footer>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            {['Open positions', 'Cruisers', 'Jobs'][value]}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <TabPanel value={value} index={0}>
          <OpenPositions />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Cruisers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Jobs />
        </TabPanel>
      </main>
    </div>
  );
}

Admin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Admin;
