const initState = {
    error: null,
    progress: null,
    status: null
};

const openPositionsReducer = (state = initState, action) => {
    switch(action.type){
        case 'ADD_POSITION':
            return {
                ...state,
                status: `New job added: ${action.payload.name}`
            }
        case 'POSITION_UPDATED':
            return {
                ...state,
                status: `Position "${action.payload.name}" updated!`
            }
        case 'POSITION_DELETED': 
            return {
                ...state,
                status: `Position "${action.payload}" deleted!`
            }
        case 'ADD_POSITION_ERROR':
        case 'POSITION_UPDATE_ERROR':
        case 'POSITION_DELETE_ERROR':
            return {
                ...state,
                error: action.payload.message
            }
        default:
            return state;
    }
}

export default openPositionsReducer;