import React, { useContext } from 'react';

import { LangContext } from '../App';

const Footer = () => {
    const lang = useContext(LangContext);

    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong>River Cruise Life</strong>. {lang === "ENG" ? 'All Rights Reserved':'Sva prava zadržana'}
                </div>
                <div className="credits">
                {lang === "ENG" ? 'Designed by':'Dizajn'}  <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
