export const about_text = {
    ENG: {
        heading: {
            title: 'Few Words About Us',
            text: 'Officially from 15 th January 2020. our company is established and we start building reputation of providing service in River Cruise Industry.'
        },
        div1: {
            title: 'What We do:',
            text: `We are looking for a quality working staff and we follow our vision of expanding yearly offer
            and providing the candidates with fair conditions of competition for job positions where
            their professional skills and experience play the crucial role.`
        },
        div2: {
            title: 'Positions for which candidates can apply are:',
            text: `Hotel manager, Assistant Hotel Manager, Receptionist, Night guard, Restaurant manager, A waiter, Head chef, Deputy Chef, Cook, Dishwasher, The Maid's Boss, The maid...`
        },
        div3: {
            title: 'Our goal',
            text: `As Employment Agency we are looking forward to provide opportunity to our future
            candidates to experience working with people from different countries, to travel all over the
            Europe, get paid for it and to experience different life style.`
        },
    },
    SRB: {
        heading: {
            title: 'Par reči o nama',
            text: 'Zvanično od 15. januara 2020. osnovana je naša kompanija i počinjemo da gradimo reputaciju pružanja usluga u industriji rečnih krstarenja.'
        },
        div1: {
            title: 'Šta radimo',
            text: `Tražimo kvalitetno radno osoblje i sledimo našu viziju širenja godišnje ponude
            i obezbeđivanje kandidata sa poštenim uslovima konkursa za radna mesta gde
            njihove profesionalne veštine i iskustvo igraju presudnu ulogu.`
        },
        div2: {
            title: 'Radna mesta za koja se kandidati mogu prijaviti su:',
            text: `Menadžer hotela, pomoćnik upravnika hotela, recepcionar, noćni čuvar,
             menadžer restorana, konobar, glavni kuvar, zamenik šefa kuhinje, kuvar, perač sudova, šefica sobarice, sobarica...`
        },
        div3: {
            title: 'Naš cilj',
            text: `Kao agencija za zapošljavanje radujemo se pružanju mogućnosti našim budućnim
            kandidatima da iskuse rad sa ljudima iz različitih zemalja, da putuju po celoj
            Evropi, da budu plaćeni za zo i iskuse drugačiji stil života.`
        },
    }
}