import React, { useContext } from 'react';

import ApplyForm from './Forms/ApplyForm';

import { LangContext } from '../App';

const Contact = () => {
  const lang = useContext(LangContext);

  return (
    <section id="contact">
      <div className="container wow fadeInUp">
        <div className="section-header">
          <h3 className="section-title">{lang === 'ENG' ? 'Apply for a Job':'Prijavi se za posao'}</h3>
          <p className="section-description">{lang === 'ENG' ? 'Apply via form below, or if u have any question send us an email':'Možete se prijaviti pomoću forme ispod, ili, ukoliko imate pitanje pošaljite nam e-mejl'}</p>
        </div>
      </div>


      <iframe title="sabac" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=vojvode%20janka%20stojicevica%2020+(River%20Cruise%20Life)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="380" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
      <div className="container wow fadeInUp mt-5">
        <div className="row justify-content-center">

          <div className="col-lg-3 col-md-4">

            <div className="info">
              <div>
                <i className="fa fa-map-marker"></i>
                <p>Kralja Radoslava 19<br />15000 Sabac, Srbija</p>
              </div>

              <div>
                <i className="fa fa-envelope"></i>
                <p>rivercruiselife@gmail.com</p>
              </div>

              <div>
                <i className="fa fa-phone"></i>
                <p>+381 66 513 9002</p>
              </div>
              
            <div>
              <a href="https://www.facebook.com/RiverCruiseLife" target="_blank" rel="noreferrer">
              <i className="fa fa-facebook"></i>
              <p>{ lang === 'ENG' ? 'Visit us on facebook':'Posetite našu facebook stranicu'}</p>
              </a>
            </div>
              
            </div>


          </div>

          <div className="col-lg-5 col-md-8">
            <ApplyForm lang={lang}/>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Contact
