import React, { useContext } from 'react';
import { LangContext } from '../App';

const CallToAction = () => {
    const lang = useContext(LangContext);
    return (
        <section id="call-to-action">
            <div className="container wow fadeIn">
                <div className="row">
                    <div className="col-lg-9 text-center text-lg-left">
                        <h3 className="cta-title">{ lang === 'ENG' ? 'Apply for a job!':'Apliciraj za posao!'}</h3>
                        <p className="cta-text">{ lang === 'ENG' ? 'You see yourself in some of these positions?':'Vidite sebe na nekoj od gore navedenih pozicija?'}</p>
                    </div>
                    <div className="col-lg-3 cta-btn-container text-center icon-box">
                        <a className="cta-btn align-middle" href="#contact">{ lang==="ENG" ? 'Apply':'Apliciraj'}</a>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default CallToAction;
