import React, { useContext, useState } from 'react';
import { LangContext, LangSwitchContext } from '../App';

import srb from '../img/icons/srbIcon.png';
import eng from '../img/icons/engIcon.png';

const Header = () => {
  const lang = useContext(LangContext);
  const switchLang = useContext(LangSwitchContext);
  const [showLangBox, setShowLangBox] = useState(false);

  const handleLang = (e, value) => {
    e.preventDefault();
    setShowLangBox(false);
    switchLang(e, value);
  }

  return (
    <header id="header">
      <div className="container">

        <div className="pull-left">
          <ul className="nav-menu lang-ul">
            <li><a href="#lang" className="nav-link-a" onMouseOver={() => setShowLangBox(true)} onClick={(e) => e.preventDefault()}>{lang}</a>
              <ul style={{ display: showLangBox ? 'block':'none'}}>
                <li><a href="#lang" onClick={(e) => handleLang(e, 'ENG')} className="nav-link-a"><div className="lang-box"><p className="title">ENG</p><img src={eng} className="lan-icon" alt="flag"/></div></a></li>
                <li><a href="#lang" onClick={(e) => handleLang(e, 'SRB')} className="nav-link-a"><div className="lang-box"><p className="title">SRB</p><img src={srb} className="lan-icon" alt="flag"/></div></a></li>
              </ul>
            </li>
          </ul>
        </div>

        <nav id="nav-menu-container">
          <ul className="nav-menu">
            <li className="menu-active"><a className="nav-link-a" href="#hero">{lang === 'ENG' ? 'Home': 'Početna'}</a></li>
            <li><a className="nav-link-a" href="#about">{lang === 'ENG' ? 'About us': 'O nama'}</a></li>
            <li><a className="nav-link-a" href="#services">{lang === 'ENG' ? 'Jobs': 'Poslovi'}</a></li>
            <li><a className="nav-link-a" href="#portfolio">{lang === 'ENG' ? 'Cruisers': 'Kruzeri'}</a></li>
            <li><a className="nav-link-a" href="#team">{lang === 'ENG' ? 'Routes': 'Rute'}</a></li>
            <li><a className="nav-link-a" href="#contact">{lang === 'ENG' ? 'Apply for a job': 'Apliciraj za posao'}</a></li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header;
