import cruiserReducer from './cruisersReducer';
import jobsReducer from './jobsReducer';
import authReducer from './authReducer';
import contactReducer from './contactReducer';
import openPositionsReducer from './openPositionsReducer';

import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
    contact: contactReducer,
    auth: authReducer,
    openPositions: openPositionsReducer,
    jobs: jobsReducer,
    cruisers: cruiserReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})


export default rootReducer;