import React, { useEffect, useState } from 'react';

import { ListItem, Avatar, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Grid, List } from '@material-ui/core';

import { Delete, Edit } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import useStyles from '../Jobs/styles';

const CruiserList = ({ handleDelete, handleEdit, currentId }) => {
    useFirestoreConnect ([{ collection: 'cruisers' }]);
    const classes = useStyles();
    const cruisers = useSelector(state => state.firestore.ordered.cruisers);
    const [cruisList, setCruisList] = useState([]);

    useEffect(() => {
        isLoaded(cruisers) && setCruisList([...cruisers])
    }, [cruisers])

    if(!isLoaded(cruisers)){
        return <div>Loading...</div>
    }

    if(isEmpty(cruisers)){
        return <div>List is empty</div>
    }

    return (
        <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>                 
            <List className={classes.demo}>
                {
                    cruisList.length && cruisList.map(cruis => {
                        return (   
                        <ListItem key={Math.random()} divider className={currentId === cruis.id ? classes.markedRow:''}>
                        <ListItemAvatar>
                            <Avatar>
                            <img src={cruis.imgUrl} alt={cruis.name} className={classes.avatarImg}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={cruis.name}
                            secondary={`${cruis.description.slice(0,50)}...`}
                            className={classes.info}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" color="primary" onClick={() => handleEdit(cruis)}>
                            <Edit />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" className={classes.deleteBtn} onClick={() => handleDelete(cruis)}>
                            <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                        </ListItem>
                    )})
                }
            </List>
        </Grid>
        </Grid>
    )
}

export default CruiserList;
