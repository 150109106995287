import React from 'react';

import { Button, Paper, Grid, Typography, Avatar, TextField } from '@material-ui/core';
import Input from '../Input';
import ProgressBar from '../ProgressBar';

import useStyles from '../styles';
import WorkIcon from '@material-ui/icons/Work';

import { useSelector } from "react-redux";

const Form = ({ job, handleClear, handleSubmit, handleFile, handleChange, formatError }) => {
    const classes = useStyles();
    const progress = useSelector(state => state.cruisers.progress);
    
    return (
        <Paper className={classes.paper}>
            <Avatar className={job.id ? classes.avatarFormAdd:classes.avatarFormEdit}>
                <WorkIcon />
            </Avatar>
            <Typography variant="h5">{job.id ? 'Edit Job':'New Job'}</Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Input value={job.title} name="title" label="Title" handleChange={handleChange} type="text" half/>
                    <Input value={job.titleSrb} name="titleSrb" label="Naziv" handleChange={handleChange} type="text" half/>
                    <Input value={job.raiting} name="raiting" label="Raiting" handleChange={handleChange} type="text" half/>
                    <Input value={job.raitingSrb} name="raitingSrb" label="Rejting" handleChange={handleChange} type="text" half/>
                    <Input value={job.department} name="department" label="Department" handleChange={handleChange} type="text" half/>
                    <Input value={job.departmentSrb} name="departmentSrb" label="Odeljenje" handleChange={handleChange} type="text" half/>
                    <Input value={job.cabin} name="cabin" label="Cabin" handleChange={handleChange} type="text" half/>
                    <Input value={job.cabinSrb} name="cabinSrb" label="Kabina" handleChange={handleChange} type="text" half/>
                    <Grid item xs={12} align="center">
                        <TextField id="file" variant="outlined" type="file" onChange={handleFile} />
                        <br/>
                        {formatError ? <Typography variant="caption" color="error">{formatError}</Typography>:''}
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="large" type="submit" fullWidth variant="outlined" className={job.id ? classes.btnEdit:classes.btnAdd}>{job.id ? 'Edit':'Add'}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="small" fullWidth variant="outlined" className={classes.btnClear} onClick={handleClear}>Clear</Button>
                    </Grid>
                </Grid>
            </form>
            { progress && <ProgressBar value={progress} /> }
        </Paper>
    )
}

export default Form;
