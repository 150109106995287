import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px'
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    list: {
        '& .MuiListItem-divider': {
            borderBottom: '1px solid #e8c30b'
        },
        '& .MuiTypography-colorTextSecondary': {
            color: '#e8c30b',
        }
    },
    editBtn: {
        color: '#e8c30b'
    },
    deleteBtn: {
        color: '#d32f2f'
    },
    info: {
        color: '#000',
    },
    avatarImg: {
      width: 'auto',
      height: '100%'
    },
    markedRow: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    epmtyList: {
      color: '#e8c30b'
    }
  }));