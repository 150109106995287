const initState = {
    applyProgress: false,
    applyStatus: null
}

const contactReducer = (state = initState, action) => {
    switch(action.type){
        case 'APPLY_PROGRESS':
            return {
                ...state,
                applyProgress: action.payload
            }
        case 'APPLY_ERROR':
        case 'APPLY_SUCCESS':
            alert(action.payload);
            return state;
        case 'RES_STATUS':
            return {
                ...state,
                applyStatus: action.payload
            }
        default:
            return state;
    }
}

export default contactReducer;