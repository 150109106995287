import React, { useState } from 'react';

//copms
import { Grid } from '@material-ui/core';
import InfoSnackbar from './InfoSnackbar';
import Form from './Form';
import OpentPositionsList from './OpenPositionsList';

import { addPosition, deletePosition, editPosition } from '../../../actions/openPositions';
import { useDispatch } from "react-redux";

const OpenPositions = () => {
    const dispatch = useDispatch();
    const [position, setPosition] = useState({
        name: '',
        nameSrb: '',
        value: 0
    });

    const handleChange = (e) => {
        let name = e.target.name;
        setPosition(s => {
            return {
                ...s,
                [name]: e.target.value
            }
        })
    }

    const handleDelete = (pos) => {
        dispatch(deletePosition(pos));
    }

    const handleEdit = (position) => {  
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        setPosition(position)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(position.id){
            dispatch(editPosition(position));
        } else {
            dispatch(addPosition(position));
        }
        handleClear();
    }

    const handleClear = () => {        
        setPosition({
            name: '',
            nameSrb: '',
            value: 0
        })
    }

    return (
        <Grid container spacing={2} justifycontent="center">
            <Grid item xs={12} md={6} xl={4}><Form position={position} handleChange={handleChange} handleSubmit={handleSubmit} handleClear={handleClear}/></Grid>
            <Grid item xs={12} md={6} xl={6}><OpentPositionsList currentId={position.id} handleDelete={handleDelete} handleEdit={handleEdit}/></Grid>
            <InfoSnackbar />
        </Grid>
    )
}

export default OpenPositions;
