import React, { useEffect, useState } from 'react';

import { ListItem, Avatar, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Grid, List } from '@material-ui/core';

import { Work, Delete, Edit } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { useFirestoreConnect , isLoaded, isEmpty } from 'react-redux-firebase';

import useStyles from './styles';

const JobList = ({ handleEdit, handleDelete, currentId }) => {
    const classes = useStyles();
    useFirestoreConnect ([{ collection: 'jobs' }]);

    const jobs = useSelector(state => state.firestore.ordered.jobs);
    const [jobList, setJobList] = useState([]);
    
    useEffect(() => {
        isLoaded(jobs) && setJobList([...jobs])
    }, [jobs])

    if(!isLoaded(jobs)){
        return <div>Loading...</div>
    }

    if(isEmpty(jobs)){
        return <div>List is empty</div>
    }

    return (
        <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={6}>                 
            <List dense  className={classes.demo}>
                {
                    jobList.length && jobList.map(job => {
                        return (   
                        <ListItem key={Math.random()} divider className={currentId === job.id ? classes.markedRow:''}>
                        <ListItemAvatar>
                            <Avatar>
                            <Work />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={job.title}
                            secondary={job.department}
                            className={classes.info}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" color="primary" onClick={() => handleEdit(job)}>
                            <Edit />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" className={classes.deleteBtn} onClick={() => handleDelete(job)}>
                            <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                        </ListItem>
                    )})
                }
            </List>
        </Grid>
        </Grid>
    )
}

export default JobList;
