import React from 'react';

import { Button, Paper, Grid, Typography, Avatar } from '@material-ui/core';
import Input from '../Input';
import ProgressBar from '../ProgressBar';

import useStyles from '../styles';
import { PersonAdd, Edit } from '@material-ui/icons';

import { useSelector } from "react-redux";


const Form = ({ position, handleChange, handleClear, handleSubmit }) => {
    const classes = useStyles();
    const progress = useSelector(state => state.openPositions.progress);
    return (
        <Paper className={classes.paper}>
            <Avatar className={position.id ? classes.avatarFormAdd:classes.avatarFormEdit}>
            {position.id ? <Edit />:<PersonAdd />}
            </Avatar>
            <Typography variant="h5">{position.id ? 'Edit Position':'Add Position'}</Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Input value={position.name} name="name" label="Title" handleChange={handleChange} type="text" half/>
                    <Input value={position.nameSrb} name="nameSrb" label="Naziv" handleChange={handleChange} type="text" half/>
                    <Input value={position.value} name="value" label="Number" handleChange={handleChange} type="text" half/>
                    <Grid item xs={12}>
                        <Button size="large" type="submit" fullWidth variant="outlined" className={position.id ? classes.btnEdit:classes.btnAdd}>{position.id ? 'Edit':'Add'}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="small" fullWidth variant="outlined" className={classes.btnClear} onClick={handleClear}>Clear</Button>
                    </Grid>
                </Grid>
            </form>
            { progress && <ProgressBar value={progress} /> }
        </Paper>
    )
}

export default Form;
