import React, { useState, useEffect } from 'react';

import { FormControl, Paper, Select, FormHelperText, TextField, Input, Checkbox, IconButton, Typography, MenuItem, CircularProgress } from '@material-ui/core';

//icons
import { BsX } from 'react-icons/bs';
//Logo
import Logo from '../../img/logo.png';

import { isLoaded } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { sendApplication } from '../../actions/contact';


const ApplyForm = ({ lang }) => {
  const dispatch = useDispatch();
  const jobs = useSelector(state => state.firestore.ordered.jobs);
  const inProgress = useSelector(state => state.contact.applyProgress);
  const status = useSelector(state => state.contact.applyStatus);
  const [jobList, setJobList] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [formatError, setFormatError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [application, setApplication] = useState({
    police: false,
    name: '',
    email:'',
    position: lang === 'ENG' ? 'Any':'Bilo koja'
  })

  useEffect(() => {
    if(status === 200) clearForm();
  }, [status])

  
  useEffect(() => {
    if(isLoaded(jobs)){
      let jobL = jobs.map(job => lang === 'ENG' ? job.title:job.titleSrb);
      setJobList([lang === 'ENG' ? 'Any':'Bilo koja', ...jobL])
    }
  }, [jobs, lang])

  useEffect(() => {
    setApplication(curr => {
      return {
        ...curr,
        position: lang === 'ENG' ? 'Any':'Bilo koja'
      }
    })
  }, [lang])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(application.name === ''){
      setError(lang === 'ENG' ? 'Name field is required':'Niste uneli Vaše ime')
    } else if(!application.police){
      setError(lang === 'ENG' ? 'accept terms of use':'prihvatite uslove korišćenja')
    } else if(!file){
      setError(lang === 'ENG' ? 'please select Your CV':'polje CV je prazno')
    } else {
      dispatch(sendApplication(application, file));
      
    }
  }

  const clearForm = () => {
    document.getElementById("file").value = "";
    setApplication({
      police: false,
      name: '',
      email:'',
      position: lang === 'ENG' ? 'Any':'Bilo koja'        
    })
  }

  const handleChange = (e) => {
    setError(null);
    let name = e.target.name;
    setApplication(state => {
      return {
        ...state,
        [name]: e.target.value
      }
    });
  }


  const type = "pdf";

  const handleFile = async (e) => {

    let selected = e.target.files[0];    

    if (selected && selected.type.includes(type)) { 
    setFile(selected);
    setFormatError(null);
    } else {
    setFile(null);           
    document.getElementById("file").value = "";
    setFormatError('please select pdf file');
    }

  }

  const handleCheck = () => {
    setError(null);
    setApplication(state => {
      return {
        ...state,
        police: !state.police
      }
    })
  }
  return (
    <div>
    <Paper elevation={2} variant='elevation' className="form-paper">
      <div className="form">
        <Typography variant="h5" gutterBottom>{ lang === 'ENG' ? 'Apply for a job':'Aplicirja za posao'}</Typography>
        <form action="" method="post" className="contactForm" onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <TextField value={application.name} type="text" name="name" variant="outlined" margin="dense" label={ lang === 'ENG' ? "Your Name":'Vaše Ime'} onChange={handleChange}/>
            <div className="validation"></div>
          </FormControl>
          <FormControl fullWidth>
            <TextField value={application.email} type="email" name="email" variant="outlined" margin="dense" label={ lang === 'ENG' ? "Your Email":'Vaša Mejl adresa'} onChange={handleChange}/>
            <div className="validation"></div>
          </FormControl>
              <FormControl variant="outlined" fullWidth margin="dense" >
              <input
                id="file"
                type="file"
                name="pdf"
                input={<Input />}
                onChange={handleFile}
              />      
              <FormHelperText>{formatError ? <span style={{color:'red'}}>{formatError}</span> : lang === 'ENG' ? 'Your CV (pdf)':'Vaš CV (pdf)'}</FormHelperText>
  
              </FormControl>
              <FormControl variant="outlined" fullWidth  margin="dense">
                <Select   
                  onChange={handleChange}
                  MenuProps={{ disableScrollLock: true }}
                  name="position"
                  defaultValue=""
                  value={application.position}
                >
                  {
                    jobList && jobList.map((job, index) => {
                      return (
                        <MenuItem value={job} key={index}>{job}</MenuItem>
                      )
                    })
                  }
                </Select>
                <FormHelperText>{lang === 'ENG' ? 'Select Position':'Izaberite Poziciju'}</FormHelperText>
              </FormControl>
              <FormControl className="form-check-box">
                <Checkbox
                  checked={application.police}
                  onChange={handleCheck}
                  name="checkedB"
                  color="primary"
                />
                <FormHelperText onClick={() => setIsOpen(true)} className="check-box-link">{lang==="ENG" ? "I accept terms of use":"prihvatam pravila korišćenja"}</FormHelperText>
              { isOpen ?
                <div id="open-modal" className="modal-window">
                  <div>
                    <div  title="Close" className="modal-close"><IconButton onClick={() => setIsOpen(false)}><BsX color="#fff"/></IconButton></div>
                    <header>
                      <img src={Logo} alt="logo" className="terms-logo"/>
                      <Typography variant="h5" gutterBottom >{lang === 'ENG' ? 'Data protection notice':'Obaveštenje o zaštiti podataka'}</Typography>
                    </header>
                      <Typography variant="body1" align="justify" gutterBottom className="terms-text">
                      {lang !== 'ENG' ? `U skladu sa Zakonom o zaštiti podataka o ličnosti (’’Službeni glasnik RS’’, br. 87/2018)
                       agencija za posredovanje u zapošljavanju River Cruise Life,
                        Vas ovim putem obaveštava da se Vaši podaci o ličnosti prikupljaju,
                         obrađuju i prenose na način da popunjavanjem naše CV Aplikacije ili slanjem radne biografije elektronskim putem 
                         ili putem društvenih mreža, omogućavate da agencija River Cruise Life obrađuje Vaše podatke u cilju pronalaženja zaposlenja.`
                         :
                         `In accordance with the Law on Personal Data Protection ("Official Gazette of RS", No. 87/2018)
                        River Cruise Life employment agency,
                         Hereby informs you that your personal data is being collected,
                          processed and transmitted in such a way that by filling out our CV Application or sending a CV electronically
                          or through social media, you allow River Cruise Life to process your information in order to find employment.`}
                      </Typography>
                    </div>
                </div>:<div className="modal-close"></div>
                }
              </FormControl>
          <div className="text-center"><button type="submit">{lang === 'ENG' ? 'Send':'Pošalji'}</button></div>
          <div className="text-center"><Typography variant="caption" color="error" align="center">{error}</Typography></div>
          
        </form>
      </div>{ inProgress ? <div className="progress-div"><CircularProgress className="form-progress"/></div>:'' }
        </Paper>
    </div>
  )
}

export default ApplyForm;
