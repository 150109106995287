import React from 'react';

const NoMatch = () => {
    return (
        <div>
            <h1>Sorry we could not find that page.</h1>
        </div>
    )
}

export default NoMatch;
