import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import JobList from './JobList';
import Form from './Form';
import InfoSnackbar from './InfoSnackbar';

import { createJob, deleteJob, updateJob } from '../../../actions/jobs';
import { useDispatch } from "react-redux";

const Jobs = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [formatError, setFormatError] = useState(null);
    const [job, setJob] = useState({
        title:'',
        titleSrb:'',
        raiting: '',
        raitingSrb: '',
        department: '',
        departmentSrb: '',
        cabin: '',
        cabinSrb: '',
    })

    const handleDelete = (job) => {
        dispatch(deleteJob(job));
        handleClear();
    }

    const type = "pdf";

    const handleFile = (e) => {
        let selected = e.target.files[0];    
        
        if (selected && selected.type.includes(type)) { 
        setFile(selected);
        setFormatError(null);
        } else {
        setFile(null);           
        document.getElementById("file").value = "";
        setFormatError('please select pdf file');
        }

    }
    
    const handleEdit = (job) => {     
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        setJob(job);
    }

    const handleClear = () => {
        setFormatError(null);
        setJob({
            title:'',
            titleSrb:'',
            raiting: '',
            raitingSrb: '',
            department: '',
            departmentSrb: '',
            cabin: '',
            cabinSrb: '',
        });
        setFile(null);    
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(job.id){
            dispatch(updateJob(file, job));
        } else {
            dispatch(createJob(file, job));
        }
        
        handleClear();
    }

    const handleChange = (e) => {
        let name = e.target.name;
        setJob(prev => {
            return {
                ...prev,
                [name]: e.target.value
            }
        })
    }
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={4}><Form formatError={formatError} job={job} handleClear={handleClear} handleSubmit={handleSubmit} handleFile={handleFile} handleChange={handleChange}/></Grid>
            <Grid item xs={12} md={6} xl={4}><JobList currentId={job.id} handleDelete={handleDelete} handleEdit={handleEdit}/></Grid>
            <InfoSnackbar />
        </Grid>
    )
}

export default Jobs;
