import React, { useState, createContext } from 'react';

import { Route, Switch } from "react-router-dom";

//components
import Layout from './components/Layout';
import NoMatch from './components/NoMatch';
import Admin from './components/Admin/Admin';
import Auth from './components/Admin/Auth';


export const LangContext = createContext();
export const LangSwitchContext = createContext();

const App = (props) => {
  const [lang, setLang] = useState('ENG');
  

  const switchLang = (e, currentLang) => {
    e.preventDefault();
    setLang(currentLang);
  }


  return (
    <div className="App">
      <LangContext.Provider value={lang}>
        <LangSwitchContext.Provider value={switchLang}>
          <Switch>
            <Route path="/" exact component={Layout} />
            <Route path='/admin' component={Admin} />
            <Route path='/auth' component={Auth} />
            <Route component={NoMatch} />
          </Switch> 
        </LangSwitchContext.Provider>
      </LangContext.Provider>
    </div>
  );
}

export default App;
