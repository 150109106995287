import React, { useState } from 'react';

import { signIn } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { Container, Grid, Typography, Paper, Button } from '@material-ui/core';
import Input from './Input';
import useStyles from './styles';

//Logo
import Logo from '../../img/logo.png';

const Auth = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth.error);
    const auth = useSelector(state => state.firebase.auth);
    const [credentials, setCredentials] = useState({
        email:'',
        password: ''
    })

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(signIn(credentials));
    }

    const handleChange = (e) => {
        let name = e.target.name;
        setCredentials(s => {
            return {
                ...s,
                [name]: e.target.value
            }
        })
    }

    if(auth.uid){
        return <Redirect to={'/admin'}/>
    }

    return (
        <div className={classes.auth}>
        <Container maxWidth='xs'>
        <div className={classes.authPaper}>
            <Paper className={classes.paperLogin}>
            <header className={classes.avatarLogin}>
                <img src={Logo} alt="logo" className={classes.img} />
            </header>
            <Typography variant="h5">Admin</Typography>
            <form className={classes.form} onSubmit={handleLogin}>
                <Grid container spacing={2}>
                    <Input value={credentials.email} name="email" label="Email" handleChange={handleChange} type="email" />
                    <Input value={credentials.pass} name="password" label="Password" handleChange={handleChange} type="password" />
                    <Grid item xs={12}>
                        <Button size="large" type="submit" fullWidth variant="outlined" className={classes.btnAdd}>Login</Button>
                    </Grid>
                    <Grid item xs={12} align="center"><Typography color="error" variant="caption">{error ? error:''}</Typography></Grid>
                </Grid>
            </form>
        </Paper>
        </div>
        </Container>
        </div>
    )
}

export default Auth;
